import "./Resume.css";
// import Card from 'react-bootstrap/Card'
// import Badge from './Badge'

function Contact() {
    return (

            <div className="hero-white">
                <div className='container'>
                    
                </div>
            </div>

    );
}

export default Contact;
